.container-sponsors {
    text-align: center;
    position: relative;
    z-index: 5;
    width: 100%;     
    background: white;      

    .sponsors {
        background: white;
        padding: 10px 0;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        margin: auto;
    }

    & img {
        width: 200px;
        min-width: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .container-sponsors {
        font-size: 1.5rem;
        img {
            width: 200px;
        }
    }
}

@media screen and (max-width: 620px) {
    .container-sponsors img {
        max-width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .container-sponsors img {
        max-width: 80%;
    }
}
