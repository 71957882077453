.header-footer {
    width: 100%;
    position: absolute;
    bottom: 130px;
    left: 0;
    display: flex;
    padding: 30px 10px;
    justify-content: center;
    align-items: center;
    gap: 15%;
    background: $color-1;
    font-family: $font-1;
    font-weight: bolder;
    color: $color-2;
    font-size: 1.2rem;
    text-align: center;

    .header-footer-center {
        line-height: 2rem;

        a {
            color: $color-2 ;
            transition: 0.3s ease-in-out;

            &:hover {
                background: $color-2;
                padding: 5px 10px;
                border-radius: 10px;
                color: $color-1;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .header-footer {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 500px) {
    .header-footer {
        padding: 10px 10px;
        font-size: 0.8rem;
    }
}
