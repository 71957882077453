.section-program {
    width: 90%;
    margin: 0 auto 200px;

    .header-program {
        text-align: center;
        display: inline;
        color: $color-1;
        background: $color-1;
        font-family: $font-1;
        font-size: 1.2rem;
        position: relative;
        

        h3 {
            font-size: 2rem;
            font-weight: bold;
            margin: 20px 0;
        }
//En attente de MAJ
        .update{
            margin: 0 auto;            
            max-width: 50%;
            border-radius: 10px;
            background: rgba(243, 71, 71, 0.923);
            color: white;
            padding: 10px;
        }
    }

    .program {
        position: relative;
        align-items: center;
        .children-left {
            width: 100%;
            box-sizing: border-box;
            box-shadow: -17px 10px 5px rgba(148, 150, 150, 0.301);
            border-radius: 10px;
            line-height: 0;

            .children-picture {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }

        .first {
            transform: rotate(-20deg);
            margin: 0 5px;
        }

        .last {
            margin: 0 5px;
            transform: scaleX(-1) rotate(-20deg);
        }

        .children-right {
            width: 100%;
            background: $color-1;
            border-radius: 10px;
            box-shadow: 14px 14px 5px rgba(0, 142, 130, 0.301);

            .nocturne-list {
                text-align: center;

                li {
                    padding: 10px;
                }
            }

            h2 {
                text-align: center;
                padding: 10px;
            }

            strong {
                text-align: center;
                font-weight: bold;
            }

            ul {
                margin: 5% 0;

                li {
                    width: 80%;
                    margin: 10px auto;
                    background: $color-2;
                    color: $color-1;
                    border-radius: 10px;
                    font-weight: bold;
                    padding: 10px;
                }
            }
            .warning {
                margin: 5% 15%;
                display: flex;
                flex-direction: column;
                justify-items: center;
                background: white;
                color: red;
                border-radius: 20px;
                animation: animWarning 4s ease-in-out infinite;

                .wild {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                }
            }

            @keyframes animWarning {
                50% {
                    background: rgba(243, 71, 71, 0.923);
                    color: white;
                }
            }
        }

        &.nocturne:after {
            position: absolute;
            content: '';
            bottom: -30px;
            border-radius: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            height: 5px;
            background: $color-1;
            box-shadow: 10px 5px 5px rgb(0, 142, 129);
        }
    }

    .end {
        margin-bottom: 350px;
    }
}
@media screen and (max-width: 600px) {
//En attente de MAJ
    .update{
       min-width: 80%;
    }
    .children-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        font-size: 1rem;

        .nordique span {
            font-size: 0.9rem;
        }
    }

    .program {
        .warning {
            padding: 20px 10px;
            line-height: 1.2rem;
        }
    }
}

@media screen and (max-width: 300px) {
    .children-right {
        .nordique span {
            font-size: 0.65rem;
        }
    }
}
