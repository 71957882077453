.container-section-archive {
    width: 80%;
    margin: auto auto 350px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    font-family: $font-1;
    font-size: 2rem;
    text-align: center;
    overflow: visible;

    $cycle: 1;
    $halfCycle: calc($cycle / 2);
    $timming: linear;
    body {
        background: $color-1;
        height: 100%;
    }
    #robot {
        position: relative;
        height: 50vh;
        width: 20vh;
        margin: 0 auto;
        animation: robot $cycle + s infinite ease-in-out;
        z-index: 2;
        div {
            position: absolute;
            transform-origin: 50% 0;
        }
        .r div {
            background: $color-1;
        }
        .l div {
            background: $color-3;
        }
        .leg.r,
        .arm.l {
            animation: trans $cycle + s $halfCycle + s infinite $timming;
        }
        .leg.l,
        .arm.r {
            animation: trans $cycle + s infinite $timming;
        }
        .head {
            top: 1vh;
            left: 2vh;
            width: 8vh;
            height: 10vh;
            margin: 0 auto;
            border-radius: 1000px;
            transform-origin: 20% 80%;
            animation: head $cycle + s infinite $timming;
            background: $color-1;
        }
        .arm {
            left: 2vh;
            top: 6.5vh;
            width: 5vh;
            height: 5.5vh;
            div {
                top: 100%;
                left: 0;
                width: 100%;
                height: 10vh;
                border-radius: 1000px;
                box-sizing: border-box;
                transform: rotate(5deg);
                animation: arms1 $cycle + s infinite $timming;
                div {
                    animation: arms2 $cycle + s infinite $timming;
                }
            }
            &.l div {
                animation-delay: -$halfCycle + s;
            }
        }
        .leg {
            top: 27vh;
            left: 4vh;
            width: 5vh;
            height: 4vh;
            div {
                border-radius: 1000px;
                width: 100%;
                height: 15vh;
                top: 100%;
                animation: legs1 $cycle + s infinite $timming;
                div {
                    animation: legs2 $cycle + s infinite $timming;
                }
            }
            &.r div {
                animation-delay: -$halfCycle + s;
            }
        }
    }
    .shadow {
        position: relative;
        width: 22vh;
        height: 3vh;
        margin: 0 auto;
        border-radius: 50%;
        background: $color-3;
        animation: shadow $cycle + s infinite $timming;
        z-index: 1;
    }
    /************************************************************************************/
    @keyframes robot {
        0%,
        50%,
        100% {
            transform: translateY(0vh) rotate(7deg);
        }
        15%,
        65% {
            transform: translateY(-4vh) rotate(5deg);
        }
    }
    @keyframes head {
        0%,
        100% {
            transform: rotate(-5deg);
        }
        25%,
        75% {
            transform: rotate(15deg);
        }
        50% {
            transform: rotate(0deg);
        }
    }
    @keyframes trans {
        0%,
        100% {
            transform: translateX(0vh);
        }
        50% {
            transform: translateX(3vh);
        }
    }
    @keyframes arms1 {
        0%,
        100% {
            transform: rotate(50deg);
        }
        50% {
            transform: rotate(-70deg);
        }
    }
    @keyframes arms2 {
        0%,
        100% {
            transform: rotate(-5deg);
        }
        50% {
            transform: rotate(-100deg);
        }
    }
    @keyframes legs1 {
        0%,
        100% {
            transform: rotate(60deg);
        }
        40% {
            transform: rotate(-90deg);
        }
    }
    @keyframes legs2 {
        0%,
        100% {
            transform: rotate(2deg);
        }
        33% {
            transform: rotate(150deg);
        }
        50% {
            transform: rotate(0deg);
        }
    }
    @keyframes shadow {
        0%,
        50%,
        100% {
            transform: scale(1.5);
        }
        25%,
        75% {
            transform: scale(0.98);
        }
    }

    .end {
        color: #326384;
        font-family: sans-serif;
        text-transform: uppercase;
        display: block;
        text-align: center;
        margin: 5%;
        font-weight: bold;
    }
}
