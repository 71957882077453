.not-found{
    margin: 30vh auto;
    text-align: center;
    color: $color-1;
    font-family: $font-1;

    h3{
        font-size: 3rem;
        letter-spacing: 0.3rem;
    }

    p{
        margin: 5vh 0;
        font-style: italic;
        font-size: 2rem;
        font-weight: bold;
    }
}