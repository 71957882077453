.btn{
    padding: 10px 20px;
    border-radius: 30px;
    color: $color-1;
    font-family: $font-1;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.5s ease-in-out;

    &:hover{
        transform: scale(1.2);
    }

    i{
        padding: 0 5px;
    }
}


.mail{
    margin: 0 auto;
    width: 250px;
    border: 2px solid white;

    &:hover{
        background: $color-1;
        color: white;
        border: 2px solid white;
    }
}

.btn-race{
    display: block;
    margin: 10px auto;
    background: $color-1;
    font-family: $font-3;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    border-radius: 20px;
    border: 2px solid white;
    transition: 0.3s ease-in-out;

    &:hover{
        background: $color-2;
        color: $color-1;
        border: 2px solid $color-1;
    }
    
}

@media screen and (max-width: 300px) {    

    .children-right{
    .btn-race{
        font-size: 0.8rem;
    }
}  
    }  

