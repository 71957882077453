.card{
    min-width: 250px;       
    display: flex;
    flex: 0 20%;    
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    border: 5px solid white;
    border-radius: 30px;
    box-sizing: border-box;
    box-shadow: 12px 14px 5px rgba(9, 9, 9, 0.368);    

    img{
        max-width: 100%;
        
    }
}