@font-face {
    font-family: 'font-1';
    src: url(../assets/fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: 'trail';
    src: url(../assets/fonts/QuattrocentoSans-Regular.ttf);
}

@font-face {
    font-family: 'date';
    src: url(../assets/fonts/PTSans-Regular.ttf);
}

$font-1: 'font-1', sans-serif;

$font-2: 'trail', sans-serif;

$font-3: 'date', sans-serif;

$color-1: rgb(0, 142, 129);
$color-2: rgb(238, 244, 238);
$color-3:rgba(0, 142, 130, 0.217);
$orange:rgb(224,148,2);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    outline: none;
    border: none;
    text-decoration: none; 

    a{
        color: #000000;

        &:visited{
            color: #000000;
        }
    }
    
      
}

html , body {
    overflow-x: hidden;
}

body {
    position: relative;
    
}

.first-container {
    padding-bottom: 50px;
}

.container-title{
    display: flex;
    justify-content: center;
    align-items: center;    
    width: 275px;
    margin: 10rem auto 3rem;
    padding: 5px 10px;
    font-family: $font-3;
    font-size: 1.6rem;
    background: $color-1;
    color: $color-2;
    border-radius: 5px 30px;
    border: 3px solid white;
    animation: animTitle 10s ease-in-out infinite;
}

@keyframes animTitle {
    50%{
        background: $color-2;
        color: $color-1;
        border: 3px solid $color-1;
    }
}

