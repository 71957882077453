.container-section-inscription {
    display: flex;
    gap: 10px;
    width: 70%;
    margin: 5% auto 25%;
    color: white;
    border-radius: 5px;
    font-family: $font-1;
    font-size: 1.2rem;
    line-height: 2rem;
    
    

    .inscription {
        font-weight: bold;
        margin: 0 auto;
        min-width: 100%;
        letter-spacing: 2px;
        //Pour inscription fermées à enlever après
        //animation: animWarning 4s ease-in-out infinite;

        p {
            font-size: 1.5rem;
            text-align: center;
        }

        h3 {
            font-size: 2rem;
        }

        .inscription-bloc {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 20px 0;

            h4 {
                font-size: 2rem;
                font-weight: bold;
            }
        }

        .containers-inscription {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            border: 5px double $color-2;
            padding: 20px;
            border-radius: 30px;

            a {
                text-align: center;
                img {
                    width: 60%;
                    min-width: 240px;
                    align-items: center;
                    border-radius: 20px;
                    transition: 0.5s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .text p {
            text-align: start;
        }

        .majoration {
            color: red;
        }
//commenter le code ci dessous lors de la reprise des inscriptions
// enlever la classe warning dans les inscriptions.js
        .warning {
            margin: 5% 15%;
            display: flex;
            flex-direction: column;
            justify-items: center;
            background: white;
            color: red;
            border-radius: 20px;
            animation: animWarning 4s ease-in-out infinite;

            .wild {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }
        }

        @keyframes animWarning {
            50% {
                background: rgba(243, 71, 71, 0.923);
                color: white;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .container-section-inscription {
        width: 95%;
    }
}

@media screen and (max-width: 300px) {
    .container-section-inscription {
        .inscription {
            .containers-inscription {
                border: none;
            }
        }
    }
}
