.content-header {
    width: 60%;
    margin: 120px auto 0;
    text-align: center;

    .site-off {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 150px;
            opacity: 0;
            animation: mucoAnim 2s ease-in-out forwards;
        }

        a {
            text-decoration: none;
            color: rgb(7, 0, 0) !important;
        }

        a:visited {
            color: black !important;
        }

        button {
            margin: 20px 0;
            font-family: $font-1;
            letter-spacing: 2px;
            font-weight: bold;
            background: $color-1;
            padding: 10px 20px;
            border-radius: 30px;
            border: 2px solid white;
            cursor: pointer;
            transition: 0.5s;
            opacity: 0;
            animation: mucoAnim 4s ease-in-out forwards;
            color: black !important;

            &:hover {
                border: 2px solid $color-1;
                background: white;
                color: $color-1 !important;
            }
        }
    }
}

.content-header::after {
    position: absolute;
    content: '';
    height: 5px;
    width: 30%;
    transform: translate(-50%);
    background: rgb(191, 229, 226);
    border-radius: 10px;
}

@keyframes mucoAnim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.new-trail {
    margin: 10px 0;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        min-height: 60vh;
        object-fit: cover;
        object-position: center;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
    }

    h1 {
        position: absolute;
        top: 55%;
        left: 54%;
        font-family: $font-2;
        font-size: 9vmin;
        text-transform: uppercase;
        color: rgb(168, 96, 1);
        font-weight: 700;
    }

    h3 {
        position: absolute;
        top: 70%;
        left: 55%;
        font-size: 7vmin;
        font-family: $font-3;
        color: white;
        font-weight: 200;
    }
}

.container-section {
    display: flex;
    gap: 5px;
    width: 80%;
    margin: 60px auto;
    color: white;
    border-radius: 5px;
    font-family: $font-1;
    font-size: 1.2rem;
    line-height: 2rem;
}

.why-trail-left {
    background-color: $color-1;
    padding: 5px 20px;
    width: 60%;
    border-radius: 10px;

    .first-title,
    h3,
    .align {
        text-align: center;
        margin: 30px 0;
    }

    i {
        background: white;
        color: $color-1;
        padding: 10px;
        margin-right: 1rem;
        border-radius: 100px;
        font-size: 2rem;
        text-align: center;
    }

    h2 {
        margin: 20px 0;
    }
    .align {
        margin: 30px 0;
    }
}

.why-trail-right {
    width: 40%;
    background-color: $color-3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

    h2 {
        text-transform: uppercase;
        color: $color-1;
    }
}

.why-trail-right img {
    width: 100%;
}

.content-register-left {
    width: 60%;
    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.content-register-right {
    width: 40%;
    border-radius: 10px;
    background: $color-1;
    padding: 30px 10px 5px;
    h2 {
        text-align: center;
    }

    p {
        font-style: italic;
        text-align: center;
    }

    ul {
        display: block;
        margin: 0 auto;

        li {
            display: flex;
            align-items: center;
            padding: 0 1rem;
            margin: 20px 0;
            font-weight: bold;
            line-height: 1.5rem;

            i {
                margin: 1rem 0.5rem;
                border-radius: 100px;
                font-size: 2rem;
            }
        }
    }

    .container-btn {
        margin: 0 auto;
        width: 50%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    em {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1500px) {
    .new-trail {
        h3 {
            left: 45%;
        }
    }
    .container-section {
        width: 100%;
        flex-direction: column;
        align-items: center;

        .why-trail-left,
        .why-trail-right,
        .content-register-left,
        .content-register-right {
            width: 90%;
        }
    }

    .container-btn {
        gap: 2rem;
        button {
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width: 900px) {
    .new-trail {
        h1 {
            left: 40%;
        }

        h3 {
            left: 35%;
        }
    }
}
